import React, {useEffect, useState} from "react"
import {Link} from "gatsby"
import _ from "lodash";
import {AppSync} from "../libraries/appsync"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageTitle from "../components/PageTitle"
import NewsList from "../components/NewsList"
import BannerList from "../components/BannerList"
import MainVisual from "../components/MainVisual"
import ProductList from "../components/ProductList";
import {Preview, cryptString} from "../libraries/preview"

export default ({location}) => {
  const [loading, setLoading] = useState(true)
  const [mv, setMv] = useState([])
  const [mvLoading, setMvLoading] = useState(true)
  const [topics, setTopics] = useState([])
  const [news, setNews] = useState([])
  const [newsLoading, setNewsLoading] = useState(true)
  const [banners, setBanners] = useState([])
  const [bannersLoading, setBannersLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setMvLoading(true)

    Preview(cryptString(location)).then(customDateTime => {
      const mainVisualQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query listMainVisual {
            listMainVisual${mainVisualQuery} {
              items {
                id
                title
                image
                sort
                linkUrl
                linkBlank
              }
            }
          }
        `
      }).then(res => {
        setMv(_.get(res.data, "data.listMainVisual.items"))
        setMvLoading(false)
      })

      // const newsQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query listNews {
            listNews {
              site_c
              news {
                site_c
                srvc_tc
                news_id
                title
                article
                stt_ts
                end_ts
                tags
                thumbnail_url
                dsptgt_tc
                tgtmember_tc
              }
            }
          }
      `
      }).then(res => {
        const news = _.get(res.data, "data.listNews.news", [])
        setNews(news)
        setNewsLoading(false)
      })

      const bannerQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query listBanners {
            listBanners${bannerQuery} {
              items {
                id
                image
                sort
                linkUrl
                linkBlank
              }
            }
          }
        `
      }).then(res => {
        setBanners(_.get(res.data, "data.listBanners.items"))
        setBannersLoading(false)
      })

      const productQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query top {
            topProducts${productQuery} {
              Hin
              Title
              NetPrice
              ecImage
              ecCategory {
                member
              }
              ecVisibleBeginDate
              ecReleaseDate
              ecSellingStatus
              ecSort
            }
            listGroups {
              items {
                hinban
                parent
                child
                groupName
              }
            }
          }
        `
      }).then(res => {
        // 公開日時:降順、品番:昇順
        const products = _.get(res.data, "data.topProducts")
        const children = _.map(_.filter(_.get(res.data, "data.listGroups.items"), g => g.parent != null && g.child == null), p => p.hinban)
        const parentProducts = _.filter(products, p => children.indexOf(p.Hin) === -1)
        // 紐づく商品グループがある場合は取得挿入
        const groups = []
        _.get(res.data, "data.listGroups.items", []).forEach(group => {
          groups[group.hinban] = group.groupName
        })
        for (const productIndex in products) {
          products[productIndex]["groupName"] = _.get(groups, products[productIndex]["Hin"], undefined)
        }

        setTopics(parentProducts)
        setLoading(false)
      })
    })
  }, [])

  return (
    <Layout>
      <SEO/>
      <section>
        <MainVisual items={_.orderBy(mv, ["sort"], ["desc"]).slice(0, 10)} loading={mvLoading}/>
      </section>
      <section className="constraint top-news-cassette">
        <PageTitle>NEWS</PageTitle>
        <NewsList items={news.slice(0, 5)} loading={newsLoading}/>
      </section>
      <section className="constraint">
        <div className="text-right">
          <Link to={"/news/"} className="right-arrow">もっと見る</Link>
        </div>
      </section>
      <section className="constraint">
        <PageTitle>PICK UP ITEM</PageTitle>
        <ProductList items={_.orderBy(
          topics,
          [
            s => s.ecSort === "11111111111" ? 0 : parseInt(s.ecSort),
            v => v.ecVisibleBeginDate,
            h => h.Hin,
          ],
          ["desc", "desc", "asc"]
        ).slice(0, 12)} loading={loading}/>
        <div className="text-right">
          <Link to={"/product/"} className="right-arrow">もっと見る</Link>
        </div>
      </section>
      <section className="constraint">
        <BannerList items={_.orderBy(banners, ["sort"], ["desc"])} loading={bannersLoading}/>
      </section>
    </Layout>
  )
}